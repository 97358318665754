import type {
  ResolvedDependency,
  V2App,
} from '@wix/ambassador-app-service-webapp/http';
import { InstallationType } from '@wix/ambassador-app-service-webapp/http';
import * as appMarketService from '../utils/appMarketService';
import { DocumentServicesAPI } from '@wix/editorx-core-api';
import {
  InstallerAppsData,
  AppInstallOption,
  InstallCallbacks,
} from '@wix/editor-platform-host-integration-apis';

export interface InstallAppWithInstallerBuilderOptions {
  origin: {
    type: string;
    initiator: string;
    info: {
      type: string;
    };
  };
  appVersion?: string;
  callback: (data: any) => void;
}

const filterActiveApp = (
  documentServices: DocumentServicesAPI,
  dependenciesApps: ResolvedDependency[],
) =>
  dependenciesApps.filter(
    ({ appId }) => appId && !documentServices.platform.isAppActive(appId),
  );

const getAppIcon = (appMarketData: V2App): string | undefined => {
  return appMarketData?.market?.marketListing?.assetsMap?.screenshots
    ?.assets?.[0]?.url;
};

const getInstallationType = (
  requestAppDefinitionId: string,
  dependentAppDefinitionId: string,
  dependenciesData: ResolvedDependency[],
): InstallationType => {
  if (!dependentAppDefinitionId) {
    return InstallationType.NO_INSTALLATION_TYPE;
  }
  const app = dependenciesData.find(
    ({ appId }) => appId && appId === dependentAppDefinitionId,
  );

  const parentRelations = app?.parentRelations || [];
  return (
    parentRelations.find((parent) => parent?.appId === requestAppDefinitionId)
      ?.installationType ?? InstallationType.NO_INSTALLATION_TYPE
  );
};

const getAppDependenciesData = async (
  documentServices: DocumentServicesAPI,
  appDefinitionId: string,
  appVersion: string,
) => {
  const dependencies = await appMarketService.getAppDependencies(
    appDefinitionId,
    appVersion,
  );

  const nonActiveDependenciesApp = filterActiveApp(
    documentServices,
    dependencies,
  );

  const appResponse = await appMarketService.getAppsAppMarketData([
    { id: appDefinitionId, version: appVersion ?? 'latest' },
    ...(nonActiveDependenciesApp
      .filter((currentApp) => currentApp.appId)
      .map(({ appId }) => appId) as string[]),
  ]);

  if (!appResponse?.apps || !appResponse?.apps?.length) {
    throw new Error('app market query return empty payload');
  }

  const apps = appResponse.apps;
  const [appToInstall, ...dependentApps] = apps;
  const appToInstallName: string | undefined = appToInstall?.name;

  const dependenciesData: InstallerAppsData[] = dependentApps.map(
    (appMarketData) => {
      return {
        label: appMarketData.name,
        appIconUrl: getAppIcon(appMarketData),
        info: appMarketData?.market?.marketListing?.basicInfo?.teaser,
        appDefinitionId: appMarketData.id as string,
        installationType: getInstallationType(
          appDefinitionId,
          appMarketData?.id as string,
          dependencies,
        ),
      };
    },
  );
  return { appToInstallName, dependenciesData };
};

const installApps = (
  documentServices: DocumentServicesAPI,
  appDefinitionIds: string[],
  appInstallOptions?: Record<string, AppInstallOption>,
  callbacks?: InstallCallbacks,
) =>
  documentServices.platform.addApps(appDefinitionIds, {
    ...appInstallOptions,
    ...callbacks,
  });

export { getAppDependenciesData, installApps };
