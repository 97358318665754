import { Shell, hot } from 'repluggable';

import { DocumentServicesAPI } from '@wix/editorx-core-api';

import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';

import { createEditorPlatformHostIntegrationAPI } from './createEditorPlatformHostIntegrationAPI';

export const EditorPlatformHostIntegrationPackage = hot(module, [
  {
    name: 'EDITOR_PLATFORM_HOST_INTEGRATION_API',

    layer: 'FLOWS',

    getDependencyAPIs() {
      return [DocumentServicesAPI];
    },

    declareAPIs() {
      return [EditorPlatformHostIntegrationAPI];
    },

    attach(shell: Shell) {
      shell.contributeAPI(EditorPlatformHostIntegrationAPI, () =>
        createEditorPlatformHostIntegrationAPI(shell),
      );
    },
  },
]);
