import { init } from './bootstrap/init';
import {
  installApps,
  getAppDependenciesData,
} from './services/installServiceEditor';
import { getComponentApp } from './services/componentService';
import {
  setEditorAPI,
  onSetAppPublicAPI,
} from './bootstrap/platformPostMessageService';
import { fetchAppsNames } from './utils/appMarketService';

const API = {
  init,
  setEditorAPI,
  applications: {
    setAppPublicAPI: onSetAppPublicAPI,
    getDependencies: getAppDependenciesData,
    install: installApps,
    fetchNames: fetchAppsNames,
  },
  components: {
    getComponentApp,
  },
};

export default API;
