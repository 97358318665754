import * as platformEvents from '@wix/platform-editor-sdk/lib/platformEvents';
import * as flowPlatformApps from './flowPlatformApps';
import * as platformPostMessageService from './platformPostMessageService';
import { DocumentServicesAPI } from '@wix/editorx-core-api';
import { InitAppOptions } from '@wix/editor-platform-host-integration-apis';

export function init(
  documentServices: DocumentServicesAPI, // TODO or documentServicesLike type
  platformApiMethods: any, // TODO fix when we type the host types
  initAppOptions?: InitAppOptions,
  extraNamespaces?: string[],
) {
  const apiForSDK = documentServices.platform.getAPIForSDK(platformApiMethods);

  platformPostMessageService.setEditorAPI(
    documentServices,
    'editorAPI',
    apiForSDK,
  );

  documentServices.platform.init(
    platformApiMethods,
    { extraNamespaces },
    initAppOptions,
  );
  flowPlatformApps.runFlowPlatformApps(documentServices);

  const onSetAppAPI = ({ apiName }: { apiName: string }) =>
    platformPostMessageService.onSetAppPublicAPI(documentServices, apiName);

  documentServices.platform.registerToPublicApiSet(onSetAppAPI);
  documentServices.platform.registerToPrivateApiSet(onSetAppAPI);

  // don't initiate event propagation in non-browser environments (i.e. unit tests)
  if (typeof MessageChannel !== 'undefined') {
    platformEvents.propagation.initMainFrame();
  }
}
