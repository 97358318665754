import coreUtilsLib from '@wix/santa-core-utils';
import * as appMarketService from '../utils/appMarketService';
import { V2App } from '@wix/ambassador-app-service-webapp/http';
import { DocumentServicesAPI } from '@wix/editorx-core-api';

const APP_DEF_ID_QUERY = 'flowAppDefId';
const APP_PARAMS_QUERY = 'flowAppParams';

const getEditorScriptUrl = (appInfo: V2App): string | undefined => {
  const components = appInfo?.components?.components;
  if (components && Array.isArray(components)) {
    const compData = components.find((comp) => comp?.compType === 'PLATFORM');
    return compData?.compData?.platformComponentData?.editorScriptUrl;
  }
  return undefined;
};

function runFlowPlatformApps(documentServices: DocumentServicesAPI) {
  const flowAppDefId = coreUtilsLib.urlUtils.getParameterByName(
    APP_DEF_ID_QUERY,
  );

  if (!flowAppDefId) {
    return;
  }

  const flowAppParamsJSON = coreUtilsLib.urlUtils.getParameterByName(
    APP_PARAMS_QUERY,
  );

  let flowAppParams = {};
  try {
    if (flowAppParamsJSON) {
      flowAppParams = JSON.parse(flowAppParamsJSON);
    }
  } catch (e) {
    console.error('Failed to parse flowAppParams. expected a JSON format.', e);
  }

  appMarketService.fetchAppInfo(flowAppDefId).then((appInfo) => {
    if (!appInfo) {
      return;
    }

    const editorScriptUrl = getEditorScriptUrl(appInfo);

    if (!editorScriptUrl) {
      return;
    }

    documentServices.platform.initApp(
      {
        appDefinitionId: flowAppDefId,
        appFields: {
          platform: {
            baseUrls: {},
            editorScriptUrl,
          },
        },
      },
      flowAppParams,
    );
  });
}

export { runFlowPlatformApps };
