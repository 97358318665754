import {
  ResolvedDependency,
  AppRequest,
  AppServiceWebapp,
  QueryAppsResponse,
  V2App,
} from '@wix/ambassador-app-service-webapp/http';

const appService = '/_api/app-service-webapp/';

const AppMarketService = AppServiceWebapp(appService).AppsV2();

const AppDependencyService = AppServiceWebapp(appService).Dependencies();

const getAppDependencies = async (
  appId: string,
  appVersion?: string,
): Promise<ResolvedDependency[]> => {
  const dependenciesByVersion = await AppDependencyService().resolveDependenciesByVersion(
    {
      appIdsToVersions: [{ appId, version: appVersion ?? 'latest' }],
    },
  );

  const dependencies =
    dependenciesByVersion?.appIdToResolvedDependencies?.[appId]?.dependencies;

  return dependencies || [];
};

const getAppsAppMarketData = (
  apps: (AppRequest | string)[],
): Promise<QueryAppsResponse> =>
  AppMarketService().query({
    appRequests: apps.map((appRequestOrId) => {
      if (typeof appRequestOrId === 'string') {
        return { id: appRequestOrId };
      }
      return appRequestOrId;
    }) as AppRequest[],
  });

const fetchAppsNames = async (
  appDefIds: string[],
): Promise<Record<string, string>> => {
  const { apps } = await getAppsAppMarketData(appDefIds);

  return Array.isArray(apps)
    ? apps.reduce((appsDict: Record<string, string>, currentApp) => {
        if (currentApp.id && currentApp.name) {
          appsDict[currentApp.id] = currentApp.name;
        }
        return appsDict;
      }, {})
    : {};
};

const fetchAppInfo = async (appDefId: string): Promise<V2App | undefined> => {
  const { apps } = await getAppsAppMarketData([appDefId]);

  return Array.isArray(apps) ? apps[0] : undefined;
};

export {
  getAppDependencies,
  getAppsAppMarketData,
  fetchAppsNames,
  fetchAppInfo,
};
