(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("repluggable"));
	else if(typeof define === 'function' && define.amd)
		define(["repluggable"], factory);
	else if(typeof exports === 'object')
		exports["EditorPlatformHostIntegration"] = factory(require("repluggable"));
	else
		root["EditorPlatformHostIntegration"] = factory(root["repluggable"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__1267__) {
return 