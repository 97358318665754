import { Shell } from 'repluggable';
import { default as api } from './apis/api';
import { CompRef } from '@wix/document-services-types';
import { DocumentServicesAPI } from '@wix/editorx-core-api';
import {
  EditorPlatformHostIntegrationAPI,
  InitAppOptions,
  InstallCallbacks,
  AppInstallOption,
} from '@wix/editor-platform-host-integration-apis';

export function createEditorPlatformHostIntegrationAPI(
  shell: Shell,
): EditorPlatformHostIntegrationAPI {
  const documentServicesAPI = shell.getAPI(DocumentServicesAPI);
  return {
    init: (
      platformApiMethods: any, // TODO fix when we type the host types
      initAppOptions: InitAppOptions,
      extraNamespaces: string[],
    ) =>
      api.init(
        documentServicesAPI,
        platformApiMethods,
        initAppOptions,
        extraNamespaces,
      ),
    setEditorAPI: (namespace: string, platformAPI: any) =>
      api.setEditorAPI(documentServicesAPI, namespace, platformAPI),
    applications: {
      setAppPublicAPI: (apiName: string) =>
        api.applications.setAppPublicAPI(documentServicesAPI, apiName),
      getDependenciesData: (appDefinitionId: string, appVersion: string) =>
        api.applications.getDependencies(
          documentServicesAPI,
          appDefinitionId,
          appVersion,
        ),
      install: (
        appDefinitionIds: string[],
        appInstallOptions?: Record<string, AppInstallOption>,
        callbacks?: InstallCallbacks,
      ) =>
        api.applications.install(
          documentServicesAPI,
          appDefinitionIds,
          appInstallOptions,
          callbacks,
        ),
      fetchNames: (appDefIds: string[]) =>
        api.applications.fetchNames(appDefIds),
    },
    components: {
      getComponentApp: (compRef: CompRef) =>
        api.components.getComponentApp(documentServicesAPI, compRef),
    },
  };
}
