import * as rpc from 'pm-rpc';
import { DocumentServicesAPI } from '@wix/editorx-core-api';

function setEditorAPI(
  documentServices: DocumentServicesAPI,
  namespace: string,
  _platformEditorAPIMethods: any, // TODO fix when we type the host types,
) {
  rpc.api.set(namespace, _platformEditorAPIMethods, {
    // @ts-expect-error
    onApiCall: documentServices.platform.reportAPICallFedOps,
  });
}

function onSetAppPublicAPI(
  documentServices: DocumentServicesAPI,
  apiName: string,
) {
  return documentServices.platform
    .requestAPIFromWorker(apiName)
    .then((api: unknown) => {
      rpc.api.set(apiName, api);
    });
}

export { setEditorAPI, onSetAppPublicAPI };
